import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

class Page500 extends Component {
  constructor(props){
    super(props);
    this.state = {
      hasError : false,

    }
    this.refreshPage = this.refreshPage.bind(this)
  }
  refreshPage() {
    this.props.history.push("./")
    window.location.reload(false);
  }
  render() {

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h1 className="float-left display-3 mr-4">500</h1>
                <h4 className="pt-3">Sorry, Something went Wrong!</h4>
                <p className="text-muted float-left">The page you are looking for is temporarily unavailable. <Link  onClick={this.refreshPage}>Go to Login Page</Link></p>



              </span>

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Page500) ;
