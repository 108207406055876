import React from "react";
import Page404 from "./Pages/Page404/Page404";
import Page500 from "./Pages/Page500/Page500"
import {Redirect, Link} from "react-router-dom"


class ErrorComponent extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hasError : false,

    }
  }
  // componentDidCatch(error, info) {

  //   console.log(error);
  //   console.log(info)

  // }
  static getDerivedStateFromError(error){
    return {
      hasError: true
    }
  }
  render(){
    console.log(this.props)
    if (this.state.hasError) {

      return(
      //  <Redirect to="/error"/>
      <Page500/>
      // <Link to = "/">Hihei</Link>
      // <Link  onClick={()=>this.props.history.push("./")}>Go</Link>
      //  <button onClick={e => this.props.history.push("./")}>homer</button>
      )
    }

    return this.props.children
  }

}
export default ErrorComponent
